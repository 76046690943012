.mainbody{
    background-color: #000;

}/* Navbar.css */

.imageLogo{
  height: 3vw;
  width: 9vw;
}



.linkFontColor{
  display: flex;
  justify-content: space-around;
  align-items: center;
        margin-right: 20px;
        color: #FFA31A;
font-family: Inter;
font-size: 1.5vw;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: start;
    background-color: #333;
    position: sticky;
    top: 0;
    z-index: 100;
    max-height: 10.25;
  }
  .navContainer{
    display: flex;
    justify-content: space-between;
    align-items: start;
    max-width: 100%;

  }

 
  .logo img {
    height: 40px; /* Set the desired height of your logo */
  }
  
  .links {
    display: flex;
  }
  
  .links a {
    color: black;
    text-decoration: none;
    margin-left: 20px;
  }
  
  .links a:hover {
    border-bottom: 2px solid black; /* Add an underline effect on hover */
  }
  .nav-link.active-link {
    color: blue ;
  }
  /* Media query for mobile responsiveness */
  @media screen and (max-width: 768px) {
    .navbar {
    }
  

    .navbar-nav{
      align-items: end;
    }
    .imageLogo{
      height: 8vw;
  width: 22vw;
    }
    .links {
      margin-top: 10px;
      margin-left: 0;
    }
  
    .links a {
      margin: 0;
      margin-right: 20px;
    }
    .navContainer.container{
      display: flex;
      justify-content: space-between;
      align-items: start;
      max-width: 100%;
  
    }
.linkFontColor{
  display: flex;
  justify-content: space-around;
  align-items: center;
        margin-right: 20px;
        color: #FFA31A;
font-family: Inter;
font-size: 2vw;
font-style: normal;
font-weight: 400;
line-height: normal;
}
button{
  background-color: #FFA31A;
}
.buttonStyNav{
  border-radius: 30px;
border: 2px solid #FF9F1A;
background: #fff;
color: #000;
font-family: Inter;
font-size: 2vw;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-right: 10px;
}
  }

  