/* .App {
  text-align: center;
  font-family: Inter
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


/* App.css */
/* 
body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}

.app-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

header {
  background-color: #333;
  color: white;
  padding: 10px;
  text-align: center;
}

main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.content {
  width: 70%;
  box-sizing: border-box;
  padding: 10px;
}

.sidebar {
  width: 25%;
  box-sizing: border-box;
  padding: 10px;
  background-color: #f4f4f4;
}

footer {
  background-color: #333;
  color: white;
  padding: 10px;
  text-align: center;
}

/* Media query for responsiveness */
/* @media screen and (max-width: 768px) {
  main {
    flex-direction: column;
  }

  .content,
  .sidebar {
    width: 100%;
  }
} */ 


/* App.css */

body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}

.app-container {
  margin: 0 auto;
  box-sizing: border-box;
}

main {
}

.centered-section-contact{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  max-height: 120.25vh;
  padding-top: 10.25vh;

  min-width: 90vw;
}

.centered-section-main{

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  min-height: 100vh;
  font-size: 11.80;

}
a{
  color: #FF9F1A;
}
.centered-section {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  max-height: 100vh;
  min-height: 100vh;
  padding-top: 10.25vh;
}

.startPadding{
  padding-top: 10.25vh;

}

.centered-section-choose{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  /* min-height: 100vh; */
  padding-top: 10.25vh;
}
.mainIntro{
  color: #FFF;

text-align: center;
font-family: Inter;
font-size: 4.25rem;
font-style: normal;
line-height: normal;
}
.mainDesc{
  color: #FFF;

text-align: center;
font-family: Inter;
font-size: 2.25rem;
font-style: normal;
line-height: normal;
}

.mainIntroquote{
  color: #FFF;

text-align: center;
font-family: Inter;
font-size: 2vw;
font-style: normal;
font-weight: 600;
line-height: normal;
}
/* Navbar.css */

.sectionTitle{
  color: #FFF;
font-family: Inter;
font-size: 6vw;
font-style: normal;
font-weight: 600;
line-height: normal;
text-decoration-line: underline;

}
.ticketsTitle{
  color: #FFF;
  font-family: Inter;
  font-size: 4vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 20px;
}

.contentDesc{
  color: #fff;
text-align: center;
padding:10px 9.6vw 0px 9.6vw;
font-family: Inter;
font-size: 2vw;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.strikeColor{
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 2vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.contentDescContact{
  color: #fff;
text-align: left;
font-family: Inter;
font-size: 2vw;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.contentDescList{
  color: #FF9F1A;
text-align: left;
font-family: Inter;

font-size: 2vw;
font-style: normal;
font-weight: 600;

}

.buttonStyle{
  border-radius: 30px;
border: 5px solid #FF9F1A;
background: #000;
color: #FFF;
color: #FFF;
font-family: Inter;
font-size: 2rem;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.contactDiv{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 90vw;
}

.listDesc{
  color:#FFF
}
.contactSec{
  color: #FFF;
font-family: Inter;
font-size: 2vw;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.footer{
  color: #FFF;
font-family: Inter;
font-size: 1.5vw;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.desktopBr{
  margin-top: 40px;
}
.desktopBrInside{
  margin-top: 40px;
}
.storesLogo{
  height: 75px;
  width: 300px;
}
.storesSection{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.homePageScreen{
  height: 66.56vh;
  width: 18vw;
}

a.disabled {
  pointer-events: none;
  cursor: default;
    
}
.unlockProfile{
  color: #FFF;
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  text-decoration-line: underline;
}
.imageLogoLink{
  height: 6vw;
  width: 6vw;
}


.profileTicketIcon{
  height: 8vw;
  width: 8vw;
}

  /* Media query for mobile responsiveness */
  @media screen and (max-width: 768px) {
   

.contactDiv{
  display: contents;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
}
.navContainer.container{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
navbar-toggler{
  display: flex;
  justify-content: end;
  align-items: start;
}

.mainIntro{
  color: #FFF;

text-align: center;
font-family: Inter;
font-size: 6.5vw;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.mainDesc{
  color: #FFF;

text-align: center;
font-family: Inter;
font-size: 1.25rem;
font-style: normal;
line-height: normal;
}


.mainIntroquote{
  color: #FFF;
text-align: center;
font-family: Inter;
font-size: 5vw;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.startPadding{
  padding-top: 0;

}
.sectionTitle{
  color: #FFF;
font-family: Inter;
font-size: 12vw;
font-style: normal;
font-weight: 600;
line-height: normal;
text-decoration-line: underline;

  }
 
  .contentDesc{
    color: #fff;
  text-align: center;
  padding:10px 9.6vw 0px 9.6vw;
  font-family: Inter;
  font-size: 4vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }


  .buttonStyle{
    border-radius: 30px;
  border: 5px solid #FF9F1A;
  background: #000;
  color: #FFF;
  color: #FFF;
  font-family: Inter;
  font-size: 4vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }


  .contentDescList{
    color: #FF9F1A;
  text-align: left;
  font-family: Inter;
  padding:0 9.6vw 0px 9.6vw;
  
  font-size: 3vw;
  font-style: normal;
  font-weight: 600;
  
  }

  .centered-section-choose{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    /* min-height: 100vh; */
    padding-top: 10.25vh;
    padding-top: 0;

  }

  .centered-section-contact{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    max-height: 120.25vh;
    padding-top: 0;
  
    min-width: 90vw;
  }

  .centered-section {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    min-height: 100vh;
    padding-top: 20.25vh;
  }
  
  .strikeColor{
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 3vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .contactSec{
    color: #FFF;
  font-family: Inter;
  font-size: 4vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 10px;
  }

  .imageLogoLink{
    height: 12.25vw;
    width: 12.25vw;
  }

  .profileHeaderTitle{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
  .footer{
    color: #FFF;
  font-family: Inter;
  font-size: 3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 10px;
  }
  .desktopBr{
    padding-top: 0px;
  }
  
.desktopBrInside{
  padding-top: 30px;
}
.homePageScreen{
  height: 56.56vh;
  width: 70vw;
}
.storesLogo{
  height: 28px;
  width: 31.53vw;
}
.storesSection{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
}
}

  